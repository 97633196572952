var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SelectMappingField from "~/components/system-manage/contact-way-config/select-mapping-field.vue";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import { ContactConfigService } from "~/services/manage-service/contact-config.service";
var ModifyContact = /** @class */ (function (_super) {
    __extends(ModifyContact, _super);
    function ModifyContact() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.level = "";
        _this.disabled = false;
        _this.primaryFields = [];
        _this.loading = {
            state: false,
        };
        // 修改机构参数
        _this.editContactModel = {
            id: "",
            name: "",
            principalId: "",
            principalName: "",
            sort: "",
            isExtension: "NO",
            hideList: [],
            // isRecord: "", //是否录入调记
            isNecessary: "NO",
            limit: "",
            attribute: "",
            propertyType: "",
            level: "",
            parent: "",
            fieldDataType: "",
        };
        /**
         * 添加机构信息校验规则
         */
        _this.editOrganizeRules = {
            principalId: [{ required: true, message: "请选择委托方", trigger: "blur" }],
            name: [{ required: true, message: "请输入配置名称", trigger: "blur" }],
            isExtension: [{ required: true, message: "请选择是否可扩展", trigger: "blur" }],
            isNecessary: [{ required: true, message: "请选择是否必填", trigger: "blur" }],
            isRecord: [{ required: true, message: "请选择是否录入调记", trigger: "blur" }],
        };
        _this.dialog = {
            selectMapping: false,
        };
        _this.mapping = {};
        _this.submitLoading = false;
        return _this;
    }
    ModifyContact.prototype.emitSuccess = function () {
        this.selectedMapping();
        this.dialog.selectMapping = false;
    };
    ModifyContact.prototype.close = function () {
        this.reset();
    };
    ModifyContact.prototype.success = function () { };
    ModifyContact.prototype.onCheckChange = function (val) {
        this.editContactModel.attribute = val.attribute;
        this.editContactModel.propertyType = val.propertyType;
        this.editContactModel.fieldDataType = val.propertyType;
    };
    ModifyContact.prototype.fresh = function (editData) {
        var _this = this;
        if (!editData)
            return;
        this.level = editData.level;
        if (editData.level !== 2) {
            this.disabled = false;
        }
        else {
            this.disabled = true;
        }
        this.loading.state = true;
        this.contactConfigService.getContactConfigById(editData.id, this.loading).subscribe(function (data) {
            _this.editContactModel = data;
            if (_this.editContactModel.principalId) {
                _this.getPrimaryFields(_this.editContactModel.principalId);
            }
            _this.contactConfigService.getFollowMarchRecord().subscribe(function (rData) {
                if (!rData)
                    return;
                _this.mapping = rData.find(function (x) { return x.attribute === data.attribute; }) || {};
            });
        });
    };
    ModifyContact.prototype.editContactanizeCommit = function () {
        var _this = this;
        // if (this.editContactModel.level === 2 && this.editContactModel.attribute === '') {
        //   this.$message.warning("请选择联动字段");
        //   return;
        // }
        var form = this.$refs["editContact"];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.loading.state = true;
            _this.contactConfigService.modifyContactConfig(_this.editContactModel, _this.loading).subscribe(function (data) {
                _this.$message.success("修改联系方式成功");
                _this.submitLoading = false;
                _this.success();
                _this.close();
            }, function (err) { return (_this.submitLoading = false); });
        });
    };
    ModifyContact.prototype.getPrincipalName = function (value) {
        var selectedprincipal = this.principalList.find(function (v) { return v.id == value; });
        if (selectedprincipal) {
            this.editContactModel.principalName = selectedprincipal.name;
        }
    };
    /**
     * 关闭窗口
     */
    ModifyContact.prototype.reset = function () {
        var form = this.$refs["editContact"];
        form.resetFields();
        this.mapping = {};
    };
    ModifyContact.prototype.selectedMapping = function () {
        this.editContactModel.attribute = this.mapping.attribute ? this.mapping.attribute : "";
        this.editContactModel.propertyType = this.mapping.propertyType ? this.mapping.propertyType : "";
        this.editContactModel.isMustInput = this.mapping.isMustInput ? this.mapping.isMustInput : "";
        if (this.editContactModel.attribute) {
            var form = this.$refs["editContact"];
            form.clearValidate("attribute");
        }
    };
    ModifyContact.prototype.getPrimaryFields = function (principalId) {
        var _this = this;
        this.contactConfigService.getAllContactConfig(principalId).subscribe(function (data) {
            _this.primaryFields = data;
        });
    };
    __decorate([
        Dependencies(ContactConfigService)
    ], ModifyContact.prototype, "contactConfigService", void 0);
    __decorate([
        State
    ], ModifyContact.prototype, "principalList", void 0);
    __decorate([
        Prop()
    ], ModifyContact.prototype, "editDataParentName", void 0);
    __decorate([
        Emit("close")
    ], ModifyContact.prototype, "close", null);
    __decorate([
        Emit("success")
    ], ModifyContact.prototype, "success", null);
    ModifyContact = __decorate([
        Component({
            components: {
                SelectMappingField: SelectMappingField,
            },
        })
    ], ModifyContact);
    return ModifyContact;
}(Vue));
export default ModifyContact;
