var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { managementService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject } from "~/core/decorator";
import { Service } from "~/core/service";
var CaseStatusService = /** @class */ (function (_super) {
    __extends(CaseStatusService, _super);
    function CaseStatusService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 删除自定义配置
     */
    CaseStatusService.prototype.deleteConfig = function (data, loading) {
        return this.netService.send({
            server: managementService.caseStatusController.deleteConfig,
            data: {
                id: data,
            },
            loading: loading,
        });
    };
    /**
     * 获取所有自定义配置
     */
    CaseStatusService.prototype.getAllConfig = function (data, page, sort, loading) {
        return this.netService.send({
            server: managementService.caseStatusController.getAllConfig,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 保存自定义配置
     */
    CaseStatusService.prototype.saveConfig = function (data, loading) {
        return this.netService.send({
            server: managementService.caseStatusController.saveConfig,
            data: {
                custConfigType: data.custConfigType,
                code: data.code,
                color: data.color,
                id: data.id,
                name: data.name,
                sort: data.sort,
                principalId: data.principalId,
                principalName: data.principalName,
                isMajor: data.isMajor,
            },
            loading: loading,
        });
    };
    /**
     * 获取对应调收结果
     */
    CaseStatusService.prototype.getContactResults = function (data, loading) {
        return this.netService.send({
            server: managementService.caseStatusController.getAllContactResults,
            data: {
                pid: data.pid,
                principalId: data.principalId,
                name: data.name,
            },
            loading: loading,
        });
    };
    /**
     * 获取对应调收结果
     */
    CaseStatusService.prototype.getAllContactResults = function (loading) {
        return this.netService.send({
            server: managementService.caseStatusController.getAllContactResults,
            loading: loading,
        });
    };
    /**
     * 保存调收结果
     */
    CaseStatusService.prototype.saveContactResult = function (data, loading) {
        return this.netService.send({
            server: managementService.caseStatusController.saveContactResult,
            data: data,
            loading: loading,
        });
    };
    /**
     * 更新调收结果
     */
    CaseStatusService.prototype.updateContactResult = function (data, loading) {
        return this.netService.send({
            server: managementService.caseStatusController.updateContactResult,
            data: data,
            loading: loading,
        });
    };
    /**
     * 获取系统匹配调记字段
     */
    CaseStatusService.prototype.getFollowMarchRecord = function (loading) {
        return this.netService.send({
            server: managementService.caseStatusController.getFollowMarchRecord,
            loading: loading,
        });
    };
    /**
     * deleteContactResult
     */
    CaseStatusService.prototype.deleteContactResult = function (data, loading) {
        return this.netService.send({
            server: managementService.caseStatusController.deleteContactResult,
            data: {
                id: data.id,
            },
            loading: loading,
        });
    };
    /**
     * deleteContactResult
     */
    CaseStatusService.prototype.generalFollowRecordFields = function (principalId, loading) {
        return this.netService.send({
            server: managementService.caseStatusController.generalFollowRecordFields,
            data: {
                principalId: principalId,
            },
            loading: loading,
        });
    };
    CaseStatusService.prototype.getFollowRecordFields = function (principalId, loading) {
        return this.netService.send({
            server: managementService.caseStatusController.getFollowRecordFields,
            data: {
                principalId: principalId,
            },
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseStatusService.prototype, "netService", void 0);
    return CaseStatusService;
}(Service));
export { CaseStatusService };
